import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function Privacy_policy() {
  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white">PRIVACY AND POLICY</h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Privacy And Policy</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section class="feature-area section-gap" id="service" style={{ textAlign: "justify" }}>
        <div class="container">
          <div class="row ">
            <div class="col-md-8 pb-20 header-text">
              <h2 >Privacy Policy</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
              <p>The information obtained from the clients, including details like the email id and contact details of the clients, is kept private with the company and is treated as confidential materials that are not shared with any third party. The company ensures that the details of the expert/tutor, collaborating with a client, are not disclosed to or shared with any third party during the tenure of client-company collaboration. The company also makes sure that the solutions of the assignments shared with the clients are stored in the company database for a period of 3 months and such solutions, within the concerned tenure, are not shared with any other client or on any other platform. Assignment Service Australia gathers payment details, including payment modes’ information for the purpose of transaction and such details are treated as thoroughly confidential with our credible payment partners like Banks, PayPal, etc, and Assignment Service Australia does not have any direct access to any of the client’s payment related information. But it has to be conveyed that if, at any case, the personal identifiable information of the client is required by the law then Assignment Service Australia has to disclose the same before concerned legal authority for protecting the rights of the company and for upholding its compliance policy in the course of any judicial proceeding or legal process that is served on the Website of the company. Assignment Service Australia reserves the right to change this declaration of privacy (the privacy policy) at any time under the management’s discretion and hence, the clients and users are advised to visit the policy page regularly for keeping themselves updated about any change. The continual use of the website of Assignment Service Australia and its facilities and applications embody the users’ or clients’ consent to changes in policies.</p>
              <div>
                <h4>Right to Forget </h4><br></br>
                <p>Assignment Service Australia ensures that if any client or user wants to delete all the associated activities and data provided to Assignment Service Australia then such request will be entertained within 14 days of raising such request. The request for such removal of information has to be sent as email to the support team at <a target="_blank" href="mailto:support@assignmentserviceaustralia.com">support@assignmentserviceaustralia.com</a>.</p>
              </div>
              <div class="mt-4">
                <h4>Pricing Policy</h4><br></br>
                <p>Assignment Service Australia provides its clients and users with a clearly articulated pricing formula that entails several factors in setting costs for different assignments differently. In this regard, the company has developed a pricing policy that can be simple for the clients and users to understand. What the client or user pays for the assignment solution/paper depends on how long the expert/tutor has to work on the project and on the nature of the project/assignment (like the level of complexity, the extent of research required, etc). Hence, it is quite understandable that not every assignment’s solutions will incur the same cost. Moreover, compared to the peers within the industry, Assignment Service Australia offers relatively more cost-effective solutions/papers without ever compromising the quality of work. To help the clients or users get into a steady relationship with the company, Assignment Service Australia offers 15% discount whenever an order is placed for the first time on our Website. Besides, the company offers bonuses to clients or users who come back with recurrent orders. </p>
              </div>

            </div>

            <div class="col-lg-4 sidebar">
              <Samples_widget />

            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Privacy_policy
